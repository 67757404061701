@import "../node_modules/ngx-float-ui/scss/theme";
@import url('https://uicdn.toast.com/editor/latest/toastui-editor.min.css');
@import '@angular/cdk/overlay-prebuilt.css';

$primary: #344D80;
$primary-light: #e1edf8;
$danger-light: #fdcaca;
$danger-dark: #7f1d1d;
$danger-mid: #fca5a5;
$secondary: #803320;
$info: #9D5425;
$success: #2A7407;

.joii-text-primary {
  @apply text-primary dark:text-slate-100;
  // color: $primary;
}

.joii-bg-primary {
  @apply bg-primary dark:bg-slate-100;
  //background: $primary;
}

.joii-bg-info {
  background: $info;
}

.joii-text-info {
  color: $info;
}

.joii-bg-success {
  background: $success;
}

.joii-border-primary {
  @apply border-primary dark:border-white;
}

.joii-border-info {
  border-color: $info;
}

.joii-bg-primary-light {
  @apply bg-primary-light dark:bg-slate-800;

}

.joii-bg-danger-light {
  background: $danger-light;
}

.joii-ring-danger-mid {
  --tw-ring-color: rgb(252, 165, 165);
}

.joii-text-danger-dark {
  color: $danger-dark;
}

.joii-text-secondary {
  @apply text-yellow-900 dark:text-orange-200
}

.joii-raised-well {
  @apply bg-white dark:bg-slate-800 dark:border-slate-800
}

.joii-btn {
  @apply cursor-pointer;
  @apply inline-flex;
  @apply justify-center;
  @apply py-3;
  @apply px-4;
  @apply border-2;
  @apply border-transparent;
  @apply shadow-sm;
  @apply font-semibold;
  @apply font-medium;
  @apply rounded-3xl;
  @apply focus:outline-none;
  @apply focus:ring-2;
  @apply focus:ring-offset-2;
}

.joii-btn-primary {
  @apply joii-btn;
  @apply text-base;
  @apply joii-bg-primary;
  @apply text-white;
  @apply hover:border-2;
  @apply disabled:cursor-not-allowed;
  @apply disabled:opacity-50;
  @apply dark:text-gray-800;
}

.joii-btn-info {
  @apply joii-btn;
  @apply text-base;
  @apply text-white;
  @apply joii-bg-info;
  @apply hover:border-2;
  @apply disabled:cursor-not-allowed;
  @apply disabled:opacity-50;
}

.joii-btn-success {
  @apply joii-btn;
  @apply text-base;
  @apply text-white;
  @apply joii-bg-success;
  @apply hover:border-2;
  @apply disabled:cursor-not-allowed;
  @apply disabled:opacity-50;
}

.joii-btn-primary-sml-round {
  @apply joii-btn;
  @apply text-base;
  @apply joii-bg-primary;
  @apply text-white dark:text-black;
  @apply rounded-lg;
  @apply hover:border-2;
  @apply disabled:cursor-not-allowed;
  @apply disabled:opacity-50;
}

.joii-btn-primary-sml-circle {
  @apply joii-btn;
  @apply text-base;
  @apply joii-bg-primary;
  @apply text-white;
  @apply rounded-full;
  @apply hover:border-2;
  @apply disabled:cursor-not-allowed;
  @apply disabled:opacity-50;
}

.joii-btn-outline {
  @apply joii-btn;
  @apply text-base;
  @apply bg-white dark:bg-slate-700;
  @apply joii-text-primary;
  @apply border-2;
  @apply joii-border-primary;
  @apply disabled:cursor-not-allowed;
  @apply disabled:opacity-50;
}

.joii-btn-info-outline {
  @apply joii-btn;
  @apply text-base;
  @apply bg-white;
  @apply joii-text-info;
  @apply border-2;
  @apply joii-border-info;
  @apply disabled:cursor-not-allowed;
  @apply disabled:opacity-50;
}

.joii-btn-outline-sml-round {
  @apply joii-btn;
  @apply text-base;
  @apply joii-text-primary;
  @apply border-2;
  @apply rounded-lg;
  @apply joii-border-primary;
  @apply disabled:cursor-not-allowed;
  @apply disabled:opacity-50;
}

.joii-btn-primary-sml-round,
.joii-btn-outline-sml-round {
  ng-icon {
    position: relative;
    top: 4px;
    margin-right: 5px;
  }
}

.google-btn {
  @apply joii-btn;
  @apply bg-blue-500;
  @apply text-white;
  @apply hover:bg-white;
  @apply hover:text-blue-500;
  @apply hover:border-blue-500;
  @apply hover:border-2;
}

.joii-btn-light {
  @apply joii-btn;
  @apply bg-white;
  @apply border-gray-300;
  @apply text-gray-500;
  @apply hover:bg-gray-50;
}

.joii-btn-danger {
  @apply joii-btn;
  @apply bg-red-500;
  @apply border-white;
  @apply text-white;
  @apply hover:bg-red-600;
}

.joii-btn-secondary {
  @apply joii-btn px-6 text-center text-cool-blue-600 text-base font-semibold leading-normal tracking-tight;
  @apply bg-white border-2 border-cool-blue-600 rounded-3xl inline-flex justify-center items-center gap-2 transition-colors duration-100 ease-in-out hover:bg-cool-blue-600 hover:text-white;
}

.form {
  @apply space-y-6;
}

.input-group {
  @apply mt-3;
  @apply grid;
  @apply grid-cols-1;
  @apply gap-y-6;
  @apply gap-x-4;
  @apply sm:grid-cols-6;
  @apply items-center;
}

select.disabled {
  @apply cursor-not-allowed;
  @apply text-zinc-400;
  @apply bg-gray-300 bg-dark-blue-900;
}

.clearfix:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}

.clearfix {
  display: inline-block;
}

.clearfix {
  display: block;
}


body {
  @include ngx-float-ui-theme(rgb(52 77 128), white);
  font-family: 'Poppins', sans-serif;
  overflow: visible;
}

.formio-component-submit,
.btn-wizard-nav-submit,
.hiddeninput,
.hiddenoutputs {
  @apply hidden
}


.rich-text-container ul {
  list-style-type: disc;
  margin-left: 20px;
}

.rich-text-container ol {
  list-style-type: decimal;
  margin-left: 20px;
}

.rich-text-container {
  .ql-editor {
    @apply p-0;

    >* {
      @apply p-0 text-gray-900 font-inter text-[16px];
    }

    li {
      padding-left: 0;
    }

    ol {
      margin-left: 0;
      padding-left: 1.5rem;
    }

    a {
      @apply underline text-blue-500;
    }
  }
}

.rich-text-container.light {
  .ql-editor {
    @apply p-0;

    >* {
      @apply p-0 text-zinc-600 font-inter text-[1rem];
    }

    li {
      padding-left: 0;
    }

    ol {
      margin-left: 0;
      padding-left: 1.5rem;
    }

    a {
      @apply underline text-blue-500;
    }
  }
}


.rich-text-container h1,
.rich-text-container h2,
.rich-text-container h3,
.rich-text-container h4,
.rich-text-container h5,
.rich-text-container h6 {
  all: unset;
  font-size: revert;
  font-weight: revert;
  margin-top: revert;
  margin-bottom: revert;
  line-height: revert;
}

.rich-text-container a {
  @apply underline text-blue-500;
}

.label-style app-label div label {
  color: #344D80;
  min-width: 150px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1.125rem;
  line-height: 1.75rem
}

.react-flow__minimap {
  background: rgba(#cdcdcd, .8);
}

.joii-input {
  @apply block w-full rounded-md border-0 px-4 py-3.5 text-[15px];

  @apply dark:bg-slate-700 dark:text-white;

  &:focus {
    box-shadow: 0px 0px 0px 4px rgba(59, 130, 246, 0.25);
  }
}

.tooltip-primary-950 {
  @include ngx-float-ui-theme(hsla(221, 38%, 21%, 1), white);
}

.language-dropdown {
  @include ngx-float-ui-theme(white, white);
  background: none !important;
  max-width: unset !important;
  padding: 0 !important;
  box-shadow: none !important;

  .float-ui-arrow {
    @apply hidden;
  }
}

app-chat-window {
  @include ngx-float-ui-theme(transparent, white);
}

// Quill Rich Text editor
quill-editor {
  display: block !important;
}

.ql-toolbar.ql-snow {
  @apply rounded-t-[10px] border border-stone-200;
  font-family: 'Inter', sans-serif;
}

.ql-container.ql-snow {
  @apply rounded-b-[10px] border border-stone-200;
  font-family: 'Inter', sans-serif;
}

.formio-component-form {
  .choices__list {
    @apply dark:bg-slate-700;

    .choices__item--selectable.is-highlighted {
      @apply dark:bg-slate-500 #{!important};
    }

    .choices__input {
      @apply dark:bg-slate-700 #{!important};
    }
  }

  .toggle {

    .form-check {
      @apply border-none
    }

    .form-check-input {
      @apply me-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-black/25 before:pointer-events-none before:absolute before:h-6 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-white after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ms-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-black/60 focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ms-[1.0625rem] checked:focus:before:scale-100;
    }

    .form-check-label {
      @apply flex flex-row gap-2;
    }
  }

  .form-group.pointClicked {
    label {
      @apply hidden;
    }

    @apply m-0 p-0;

    .dropdown {
      @apply text-slate-600 border-none ring-0 shadow-none p-0 capitalize text-left text-lg
    }

    .choices {
      @apply p-0 m-0
    }

    .formio-select-autocomplete-input {
      @apply hidden
    }
  }

  .form-control.open-modal-button {
    table {
      @apply hidden;


    }

    @apply text-center ring-offset-slate-100 ring-offset-0 bg-slate-100 border-slate-100 w-32 gap-2.5 px-4 py-3.5 rounded-md justify-start items-center flex text-blue-900 text-xs font-medium font-['Inter'] leading-none tracking-tight;
    font-size: 0px;
    --tw-ring-shadow:none;

    &:not(:disabled):not(.disabled):hover {
      @apply text-center gap-2.5 ring-offset-0 ring-offset-slate-100 bg-slate-100 border-slate-100 rounded-md justify-start items-center flex text-blue-900 leading-none tracking-tight;
    }

    &:after {
      content: "Specific parts of the body";
      font-size: 0.75rem;
    }
  }

  .formio-tagpad-image-container {
    @apply w-2/3;
    max-width: 100% !important;
    max-height: 100% !important;
  }

  .formio-tagpad-form-container {
    @apply w-1/3;
    max-width: 100% !important;
    max-height: 100% !important;
  }

  .formio-modified:not(.has-error) &.toggle {
    .form-check {
      .form-check-label {
        @apply text-black dark:text-white text-md font-bold font-['Inter'] leading-none tracking-tight;
      }
    }


  }

  .btn-warning {
    @apply bg-white border-red-500 px-4 py-3.5 rounded-lg justify-center items-center gap-2.5 flex text-center text-red-500 tracking-tight;

    &:not(:disabled):not(.disabled) {
      @apply hover:bg-red-500 hover:border-red-500
    }
  }

  .radio,
  .form-radio {

    input[type="radio"] {
      @apply hidden;
    }
  }

  .form-check-inline,
  .radio,
  .form-check &:not(.toggle) {



    .form-check-label {
      @apply h-[38px] px-2.5 py-[11px] bg-slate-100 dark:bg-blue-800 rounded-md justify-start items-center gap-[3px] flex text-blue-900 dark:text-neutral-100 text-xs font-medium font-['Inter'] leading-none tracking-tight peer-checked:bg-blue-500 peer-checked:text-white;
    }

    &.radio-selected {
      .form-check-label {
        @apply bg-blue-500 dark:bg-sky-100;
        @apply text-white dark:text-blue-900;
      }
    }


  }

  .formio-modified:not(.has-error) {

    .form-check-inline,
    .radio,
    .form-check &:not(.toggle) {

      .form-check-label {
        @apply h-[38px] px-2.5 py-[11px] bg-slate-100 dark:bg-blue-800 rounded-md justify-start items-center gap-[3px] flex text-blue-900 dark:text-neutral-100 text-xs font-medium font-['Inter'] leading-none tracking-tight peer-checked:bg-blue-500 peer-checked:text-white;
      }

      &.radio-selected {
        .form-check-label {
          @apply bg-blue-500 dark:bg-sky-100;
          @apply text-white dark:text-blue-900;
        }
      }
    }


  }

  .grid {
    @apply flex flex-row
  }

  .form-radio {
    @apply flex flex-row flex-1 flex-wrap space-x-1
  }

  .field-wrapper {
    @apply flex flex-col flex-1
  }

  .field-content {
    margin-left: 10px !important;
  }

  .col-form-label,
  label,
  .card-title,
  .form-text {
    @apply text-neutral-800 dark:text-white text-base font-normal font-['Inter'] leading-normal tracking-tight
  }

  .col-form-label {
    @apply font-bold;
  }

  .form-group {
    @apply pl-2;
    @apply pb-2;
  }

  .formio-component-container {
    &:after {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      @apply border-gray-300;
      @apply border-t-1;
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }

  .card-header {
    @apply bg-sky-100
  }

  .card, .card-header {
    @apply dark:border-sky-100
  }

  .card-header-tabs {
    @apply bg-white dark:bg-slate-700;

    .nav-tabs {
      .nav-item {
        @apply whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:hover:text-white;

        .active {
          @apply border-blue-900 joii-text-primary whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer;
        }
      }
    }
  }

  .card {
    @apply ml-4
  }

  .input-group>.form-control:not([type=hidden]) {
    width: auto;
  }

  .col-form-label {
    &:after {
      content: "optional";
      @apply pl-1 text-center text-gray-500 text-sm font-medium font-['Inter'] tracking-tight
    }
  }

  .field-required {

    &:after {
      content: "" !important;
    }
  }


  .formio-day-component-year,
  .formio-day-component-day,
  .form-control {
    @apply joii-input dark:bg-slate-700 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600
  }

  .formio-editor-read-only-content {
    @apply dark:text-slate-900;
  }

  .formio-component-panel {
    .card-body {
      @apply dark:bg-slate-700 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600
    }

    .card-header {
      @apply dark:bg-slate-700 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600
    }
  }

  // this is a bit of a hack due to formio not adding correct classes for font-awesome icons
  .formio-component-signature {
    .signature-pad-refresh {
      // when scrolling on the prescription page of the PMS during a consult,
      // the refresh button of the signature canvas sits above the navigation panel.
      // drop the Z index under the navigation panel.
      z-index: 28;

      .refresh::before {
        content: "\f021";
      }
    }
  }

  // this is a bit of a hack due to formio not adding correct classes for font-awesome icons
  .formio-component-datagrid {
    .formio-button-remove-row {
      .remove-circle::before {
        content: "\f014";
      }
    }
  }

  // this is a bit of a hack due to formio not adding correct classes for font-awesome icons
  .formio-component-datetime {
    .input-group-text {
      .calendar::before {
        content: "\f073";
      }
    }
  }

  .formio-modified:not(.has-error) label {
    @apply text-gray-900 dark:text-white
  }

  .choices__input {
    @apply dark:bg-slate-700
  }


  .input-group {
    @apply gap-x-0 flex flex-row;

    .form-control {
      @apply h-12
    }

    .input-group-prepend,
    .input-group-append {
      @apply h-12
    }
  }

  .formio-tagpad-remove-button {
    @apply hidden
  }

}

.formio-dialog.formio-dialog-theme-default .formio-dialog-content.formio-dialog-content {
  @apply w-2/3 m-1 min-h-20 dark:bg-slate-700;

  .btn {
    @apply m-1
  }

  div>.btn {
    @apply flex-auto mt-5
  }

  .card {
    @apply dark:bg-slate-700
  }
}

.showValidationErrors {
  .formio-component-form {
    .required:not(.formio-modified) {
      .form-check-label {
        @apply bg-pink-100 dark:bg-pink-100;
        @apply text-red-700 dark:text-red-700;
      }
    }

    .has-error {
      .form-check-label {
        @apply bg-pink-100 dark:bg-pink-100;
        @apply text-red-700 dark:text-red-700;
      }
    }
  }
}

.OT_widget-container {
  background: none !important;
}

// PMS Rich Text Editor treatment plan
.treatmentPlan .toastui-editor-defaultUI {
  @apply border-0;

  .toastui-editor-toolbar,
  .toastui-editor-mode-switch {
    display: none !important;
  }
}

.toastui-editor-contents {
  @apply dark:bg-slate-700;

  p {
    @apply dark:text-white
  }
}

// PMS Rich Text Editor clinical notes
.notes .toastui-editor-defaultUI {
  @apply border;
  @apply border-stone-200;
  @apply shadow rounded-[10px];
  @apply overflow-hidden;

  .toastui-editor-toolbar,
  .toastui-editor-mode-switch {
    display: none !important;
  }


}

.OT_archiving {
  display: none !important;
}

// CDK overlay
.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.dialog-backdrop {
  @apply bg-neutral-900/50 backdrop-blur-sm;
}

// .trail-table app-sortable-table table th:nth-child(2),
// .trail-table app-sortable-table table td:nth-child(2) {
//     max-width: 100px;
// }

.trail-table app-sortable-table div {
  overflow: visible;
}

td.list-trials-id-col{
  width: 10%;
  max-width:10%;
  
}

td.list-trials-name-col{
  width: 35%;
  max-width: 35%;
  
  span{
    width: 100%;
    max-width: 100%;
  }
}

td.list-trials-description-col{
  width: 40%;
  max-width: 40%;
  
  span{
    width: 100%;
    max-width: 100%;
  }
}

td.list-trials-status-col{
  width: 15%;
  max-width: 15%;
  
  span{
    width: 100%;
    max-width: 100%;
  }
}

.trials-table table{
  table-layout: auto;
  width: 100%;

  th, td {
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap;
  }
}

.float-ui-container.float-ui-fixed {
  text-wrap: wrap;
}