.str-video {
    @apply text-white
}

.closed-captions {
    @apply min-h-10 bg-slate-600 rounded-md w-full
}

.closed-captions__speaker {
    @apply text-white font-semibold mr-1 ml-1
}

.closed-captions__text {
    @apply text-white
}

.str-video__call-controls {
    @apply flex flex-wrap sticky bottom-0
}

.pip-window {
    @apply bg-slate-800
}